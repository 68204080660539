<template>
  <div class="about">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1>{{$t('about')}}</h1>
          <template v-if="isLoading">
            <div
              class="d-flex w-100 align-items-center justify-content-center"
              style="padding:100px 0px;"
            >
              <b-spinner
                style="width: 3rem; height: 3rem;"
                variant="warning"
              ></b-spinner>
            </div>
          </template>
          <template v-else>
            <div class="text" v-html="$utils.getLocaleAboutFulltext(about, $i18n.locale)"></div>
          </template>
          <div class="accordion" role="tablist">
            <b-card v-for="(section, index) in about.sections" :key="index" class="mb-1" no-body>
              <b-card-header header-tag="header" class="p-1 bg-white d-flex align-items-center" role="tab">
                <b-button @click="toggle(index)" v-b-toggle="'collapse-' + index" class="text-left w-100" variant="link" style="color: black; text-align: left; text-decoration: none;">
                    {{ section.title }}
                  </b-button>
                <div>
                <div class="plusminus" :class="{ active: activeIndex === index }"></div>
                </div>
              </b-card-header>
              <b-collapse :id="'collapse-' + index" accordion="my-accordion" role="tabpanel">
                <b-card-body class="bg-white" style="color: black;">
                  <b-card-text>
                    {{ section.subtitle }}
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BCollapse, BButton, BSpinner } from "bootstrap-vue";
import firebase from "firebase/app";
export default {
  data() {
    return {
      isLoading: false,
      activeIndex: null,
      about: {},
    };
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BButton,
    BSpinner,
  },
  methods: {
    getAbout() {
      this.isLoading = true;
      firebase
        .firestore()
        .collection("pages")
        .doc("about")
        .get()
        .then((doc) => {
          this.isLoading = false;
          if (doc.data()) {
            this.about = doc.data();
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    toggle(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
  },
  mounted() {
    this.getAbout();
  },
};
</script>

<style lang="scss" scoped>
.about {
  color: #fff;
}
.text {
  span {
    color: #fff !important;
  }
  
}
button:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: inherit !important;
  border: none !important;
}
.plusminus {
	position: relative;
	width: 25px;
	height: 25px;
	cursor: pointer;
  margin-right: 20px;
	
	&.active {
		&:before {
			transform: translatey(-50%) rotate(-90deg);
			opacity: 0;
		}
		&:after {
			transform: translatey(-50%) rotate(0);
		}
	}
	
	&:before , &:after {
		content: "";
		display: block;
		background-color: #333;
		position: absolute;		
		top: 50%; left: 0;
		transition: .35s;
		width: 100%;
		height: 3px;
	}
	
	&:before {		
		transform: translatey(-50%);
	}
	
	&:after {
		transform: translatey(-50%) rotate(90deg);
	}
	
}
</style>
